.boxBg {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Toastify__toast-container.Toastify__toast-container--top-center {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.AppText {
    text-align: center;
}


.h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.container {
    display: flex;
    justify-content: center;
    margin: auto;
}

.left-column {
    flex: 1;
    padding: 20px;
    margin-left: auto;
}

.middle-column {
    flex: 1;
    padding: 20px;
}

.right-column {
    flex: 1;
    padding: 20px;
}

.custom-placeholder::placeholder {
    font-size: 18px;
    /* Adjust the font size as needed */
}

.p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

.custom-placeholder {
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    padding: 5px;
}