@tailwind base;
@tailwind components;
@tailwind utilities;

.css-t3ipsp-control {
    border-color: #06bbac !important;
}

.css-12a83d4-MultiValueRemove:hover {
    background-color: #06bbac !important;
    color: white !important;
}

.bg {
    background: linear-gradient(150deg, rgba(9, 9, 121, 0.8) 40%, rgba(0, 212, 255, 1) 100%)
}

.bt_bg {
    background: linear-gradient(150deg, rgba(9, 9, 121, 0.8) 40%, rgba(0, 212, 255, 1) 100%)

}

.bg-simple {
    background-color: rgb(9, 9, 121, 0.8)
}
.css-1nmdiq5-menu{
    z-index: 999 !important;
}